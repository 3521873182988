import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { HomeService } from "../../../services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions";

const useHome = (location, t) => {
  const [roomList, setRoomList] = useState([]);
  const [notAvailable, setNotAvailable] = useState([]);
  const [initialRoom, setInitialRoom] = useState(false);
  const [loader, setLoader] = useState("notset");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.removeItem("service");
  localStorage.removeItem("service1");
  localStorage.removeItem("adult");
  localStorage.removeItem("amountdata1");
  localStorage.removeItem("id");
  localStorage.removeItem("rooms");
  localStorage.removeItem("children");
  localStorage.removeItem("amountdata");

  localStorage.removeItem("rzp_device_id");
  useEffect(() => {
    localStorage.removeItem("datetime1");
    localStorage.removeItem("datetime2");
    localStorage.setItem("datetime1", localStorage.getItem("datetimes"));
    localStorage.setItem("datetime2", localStorage.getItem("datetimes1"));
    localStorage.removeItem("datetimes");
    localStorage.removeItem("datetimes1");
    // location?.state?.checkindate=""
    // location?.state?.checkoutdate=""
  }, []);
  /**
   * get room list
   */
  useEffect(() => {
    if (
      new Date(location?.state?.checkindate) >=
      new Date(location?.state?.checkoutdate)
    ) {
      dispatch(alertActions.error(t("Checkin_and_Checkout_Date_is_Invalid")));
      HomeService.roomlist()
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setRoomList(res?.data?.data?.rooms);
            setLoader("isfull");
            setInitialRoom(true);
            localStorage.setItem("datetimes", null);
            localStorage.setItem("datetimes1", null);
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    } else if (
      location?.state?.checkindate === undefined ||
      location?.state?.checkoutdate === undefined
    ) {
      HomeService.roomlist()
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setRoomList(res?.data?.data?.rooms);
            setLoader("isfull");
            setInitialRoom(true);
            localStorage.setItem("datetimes", null);
            localStorage.setItem("datetimes1", null);
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    } else {
      if (location?.state?.id) {
        setLoader("loading");
        HomeService.getRoomList(
          location?.state?.checkindate,
          location?.state?.checkoutdate
        )
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setInitialRoom(false);

              setRoomList(res?.data?.data?.rooms);
              setNotAvailable(res?.data?.data?.nonAvailableRooms);
              setLoader("isfull");
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      } else {
        setLoader("loading");
        HomeService.getRoomList(
          location?.state?.checkindate,
          location?.state?.checkoutdate
        )
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setInitialRoom(false);
              setRoomList(res?.data?.data?.rooms);
              setNotAvailable(res?.data?.data?.nonAvailableRooms);
              setLoader("isfull");
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }, [location?.state?.id, location]);

  /**
   * navigate
   * @param {*} data
   */
  const selectRoom = (data, roomsavailable) => {
    if (
      new Date(location?.state?.checkindate) >=
      new Date(location?.state?.checkoutdate)
    ) {
      dispatch(alertActions.error(t("Checkin_and_Checkout_Date_is_Invalid")));
    } else {
      if (
        localStorage.getItem("datetimes") &&
        localStorage.getItem("datetimes1")
      ) {
        localStorage.setItem("datetime1", localStorage.getItem("datetimes"));
        localStorage.setItem("datetime2", localStorage.getItem("datetimes1"));
        localStorage.removeItem("datetimes");
        localStorage.removeItem("datetimes1");
        localStorage.removeItem("adult");
        localStorage.removeItem("children");
        localStorage.removeItem("rooms");
        localStorage.removeItem("amountdata1");
        localStorage.removeItem("amountdata");
        localStorage.removeItem("service");
        localStorage.removeItem("service1");
        navigate(`/standard?id=${data}`, {
          state: {
            id: data,
            loc: location?.state?.loc,
            roomsavailable: roomsavailable,
          },
        });
      } else {
        dispatch(
          alertActions.error(t("Please enter Check-in and Check-out date"))
        );
      }
    }
  };

  return {
    selectRoom,
    roomList,
    notAvailable,
    loader,
    initialRoom,
  };
};
export default useHome;
